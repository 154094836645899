var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"wrap fw",on:{"click":_vm.hidenAsideRight}},[_c('div',{},[_c('div',{staticClass:"header"},[_vm._m(0),_c('div',{staticClass:"w1180 clearfix"},[_vm._m(1),_c('div',{staticClass:"fr pt10 pb10 phone-nav hidden-pc"},[_c('i',{staticClass:"el-icon-s-unfold",on:{"click":function($event){$event.stopPropagation();return _vm.changeMenu.apply(null, arguments)}}})]),_vm._m(2),_vm._m(3)])]),_c('div',{staticClass:"banner clearfix"},[_c('div',{attrs:{"id":"full-screen-slider"}},[_c('el-carousel',{attrs:{"height":_vm.height}},_vm._l((3),function(item,index){return _c('el-carousel-item',{key:item},[_c('el-image',{attrs:{"src":require(`@/assets/images/banner${index + 1}.jpg`)}})],1)}),1)],1)]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"w1180 track-form"},[_c('div',{staticClass:"form3 clearfix"},[_c('div',{staticClass:"clearfix"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.no),expression:"form.no"}],attrs:{"name":"textarea","rows":"2","cols":"20","id":"textarea","placeholder":"多个运单号用英文逗号分隔"},domProps:{"value":(_vm.form.no)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "no", $event.target.value)}}}),_c('button',{staticClass:"button",staticStyle:{"outline":"none"},attrs:{"value":"物流查询 / TRACK"},on:{"click":_vm.onSubmit}},[_vm._v(" 物流查询 / TRACK ")]),_c('button',{staticClass:"upload-btn",staticStyle:{"outline":"none"},attrs:{"type":"button","onclick":"javascript:window.location.href='/idcard'"}},[_vm._v(" 身份证上传 / UPLOAD ")]),_vm._m(4)])])]),_vm._m(5)])]),_vm._m(6)])]),_c('aside',{staticClass:"ui-aside asideRight w23",style:(_vm.showAsideRight
          ? 'top: 0px; bottom: 0px; right: -1px; width: 120px;'
          : 'top: 0px; bottom: 0px; right: -4rem; width: 0')},[_vm._m(7)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top tr hidden-phone"},[_c('div',{staticClass:"w1180"},[_c('div',{staticClass:"fl"},[_vm._v(" 您好，欢迎来到三象速递官网 ")]),_c('div',{staticClass:"fr"},[_vm._v(" www.sxexpress.com.au ")]),_c('div',{staticClass:"cl"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"fl pt10 pb10 logo"},[_c('a',{attrs:{"href":"/"}},[_c('img',{attrs:{"src":require("../../assets/images/logo.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fr mt20 ml30 hidden-phone",staticStyle:{"position":"relative"}},[_c('ul',[_c('li',{staticClass:"fr ml20"},[_c('a',{attrs:{"href":"javascript:;","onmouseover":"document.getElementById('weixinDIV').style.display = 'block';","onmouseout":"document.getElementById('weixinDIV').style.display = 'none';"}},[_c('img',{attrs:{"src":require("../../assets/images/top-wx.png"),"alt":"与微信客服交流"}})])]),_c('li',{staticClass:"fr"},[_c('a',{attrs:{"href":"http://wpa.qq.com/msgrd?v=3&uin=3376684454&site=qq&menu=yes"}},[_c('img',{attrs:{"src":require("../../assets/images/top-qq.png"),"alt":"与QQ客服交流"}})])])]),_c('div',{staticStyle:{"position":"absolute","right":"0px","top":"45px","display":"none","z-index":"10000"},attrs:{"id":"weixinDIV","onmouseover":"this.style.display = 'block';","onmouseout":"this.style.display = 'none';"}},[_c('img',{staticStyle:{"width":"140px"},attrs:{"src":require("../../assets/images/wx.jpg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav fr hidden-phone"},[_c('ul',[_c('li',[_c('a',{attrs:{"href":"/"}},[_vm._v("首页")])]),_c('li',[_c('a',{attrs:{"href":"/service"}},[_vm._v("特色服务")])]),_c('li',[_c('a',{attrs:{"href":"/about"}},[_vm._v("关于三象")])]),_c('li',[_c('a',{attrs:{"href":"/contact"}},[_vm._v("联系三象")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"track-tel hidden-phone"},[_c('img',{attrs:{"src":require("../../assets/images/tel.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w1180"},[_c('h2',{staticClass:"f26 index-title"},[_vm._v(" 产品业务"),_c('span',{staticClass:"f18 n",staticStyle:{"color":"#486c84"}},[_vm._v(" / Product")]),_c('i',{staticClass:"f12 pl20",staticStyle:{"color":"#888"}},[_vm._v("产品服务多样化，提供澳洲本土快递、澳中国际空运与海运等专业化物流解决方案。")])]),_c('div',{staticClass:"service clearfix"},[_c('ul',{staticClass:"tc"},[_c('li',[_c('a',{attrs:{"href":""}},[_c('div',{staticClass:"icon1 icon"},[_c('span')]),_c('br'),_c('b',[_vm._v("澳中超快件")]),_c('br'),_c('p',[_vm._v("澳中“优先行”转运服务")])])]),_c('li',[_c('a',{attrs:{"href":""}},[_c('div',{staticClass:"icon2 icon"},[_c('span')]),_c('br'),_c('b',[_vm._v("澳中特惠件")]),_c('br'),_c('p',[_vm._v("澳中“经济行”转运服务")])])]),_c('li',[_c('a',{attrs:{"href":""}},[_c('div',{staticClass:"icon4 icon"},[_c('span')]),_c('br'),_c('b',[_vm._v("澳中奶粉件")]),_c('br'),_c('p',[_vm._v("奶粉包税转运专线")])])]),_c('li',[_c('a',{attrs:{"href":""}},[_c('div',{staticClass:"icon3 icon"},[_c('span')]),_c('br'),_c('b',[_vm._v("澳洲集货转运")]),_c('br'),_c('p',[_vm._v("流水线作业，集中仓储")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foot"},[_c('div',{staticClass:"copyright w1180 tc"},[_c('p',[_vm._v(" Copyright © 2017-2020 三象速递     联系电话：+61 1300965539     地址：Po Box 6635, Point Cook, VIC 3030    "),_c('a',{attrs:{"href":"http://www.kuaidi100.com/","target":"_blank"}},[_vm._v("快递查询")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('a',{attrs:{"href":"/"}},[_vm._v("首页")])]),_c('li',[_c('a',{attrs:{"href":"/service"}},[_vm._v("特色服务")])]),_c('li',[_c('a',{attrs:{"href":"/about"}},[_vm._v("关于三象")])]),_c('li',[_c('a',{attrs:{"href":"/contact"}},[_vm._v("联系三象")])])])
}]

export { render, staticRenderFns }