<!--
 * @Descripttion: 
 * @FilePath: /sxexpress/src/views/main/index.vue
 * @Author: 张兴业
 * @Date: 2021-03-30 13:24:34
 * @LastEditors: 张兴业
 * @LastEditTime: 2021-09-13 13:15:16
-->
<template>
  <div>
    <!-- <form name="form1" id="form1"> -->
    <!-- <div>
        <input
          type="hidden"
          name="__VIEWSTATE"
          id="__VIEWSTATE"
          value="/wEPDwULLTE3OTUzNjI5MTNkZJksZMDFKXWeB+Z2QVSySzjJspnqdHEJSairASeKGoH+"
        />
      </div>

      <div>
        <input
          type="hidden"
          name="__VIEWSTATEGENERATOR"
          id="__VIEWSTATEGENERATOR"
          value="90059987"
        />
        <input
          type="hidden"
          name="__EVENTVALIDATION"
          id="__EVENTVALIDATION"
          value="/wEdAAMAXcNn5swz3EPGAh272F5+Pta94tOclXNL6eWGKI3+Ho7U3Vc0WZ+wxclqyPFfzmMDJHeYi2b5zEpDEBGzDRne5JMZcxuDI7KRhtIqXYO0aw=="
        />
      </div> -->
    <div class="wrap fw" @click="hidenAsideRight">
      <div class="">
        <!--START: Header -->

        <div class="header">
          <div class="top tr hidden-phone">
            <div class="w1180">
              <div class="fl">
                您好，欢迎来到三象速递官网
              </div>
              <div class="fr">
                www.sxexpress.com.au
              </div>
              <div class="cl"></div>
            </div>
          </div>

          <div class="w1180 clearfix">
            <h2 class="fl pt10 pb10 logo">
              <a href="/"><img src="../../assets/images/logo.png"/></a>
            </h2>
            <div class="fr pt10 pb10 phone-nav hidden-pc">
              <!-- <i class="iconfont icon-sort"></i> -->
              <i class="el-icon-s-unfold" @click.stop="changeMenu"></i>
            </div>
            <div class="fr mt20 ml30 hidden-phone" style="position: relative;">
              <ul>
                <li class="fr ml20">
                  <a
                    href="javascript:;"
                    onmouseover="document.getElementById('weixinDIV').style.display = 'block';"
                    onmouseout="document.getElementById('weixinDIV').style.display = 'none';"
                  >
                    <img
                      src="../../assets/images/top-wx.png"
                      alt="与微信客服交流"
                    />
                  </a>
                </li>
                <li class="fr">
                  <a
                    href="http://wpa.qq.com/msgrd?v=3&amp;uin=3376684454&amp;site=qq&amp;menu=yes"
                  >
                    <img
                      src="../../assets/images/top-qq.png"
                      alt="与QQ客服交流"
                    />
                  </a>
                </li>
              </ul>
              <div
                id="weixinDIV"
                onmouseover="this.style.display = 'block';"
                onmouseout="this.style.display = 'none';"
                style="position: absolute; right: 0px; top: 45px; display: none; z-index: 10000;"
              >
                <img src="../../assets/images/wx.jpg" style="width: 140px;" />
              </div>
            </div>
            <div class="nav fr hidden-phone">
              <ul>
                <li><a href="/">首页</a></li>
                <!-- <li><a href="news.aspx">活动公告</a></li> -->
                <li><a href="/service">特色服务</a></li>
                <li><a href="/about">关于三象</a></li>
                <li><a href="/contact">联系三象</a></li>
              </ul>
            </div>
          </div>
        </div>

        <!--END: Header -->
        <!--START: Banner-->
        <div class="banner clearfix">
          <!-- 代码 开始 -->
          <div id="full-screen-slider">
            <el-carousel :height="height">
              <el-carousel-item v-for="(item, index) in 3" :key="item">
                <el-image
                  :src="require(`@/assets/images/banner${index + 1}.jpg`)"
                ></el-image>
              </el-carousel-item>
            </el-carousel>
            <!-- <ul id="slides">
                <li :style="{ background: `url(${banner1})` }">
                  <a href="javascript:;"></a>
                </li>
                <li :style="{ background: `url(${banner2})` }">
                  <a href="javascript:;"></a>
                </li>
                <li :style="{ background: `url(${banner3})` }">
                  <a href="javascript:;"></a>
                </li>
              </ul>
              <ul id="pagination" style="margin-left: 380px;">
                <li class=""><a href="#">1</a></li>
                <li class=""><a href="#">2</a></li>
                <li class="current"><a href="#">3</a></li>
              </ul> -->
          </div>
          <!-- 代码 结束 -->
        </div>
        <!--END: Banner-->
        <!--START: Content -->
        <div class="content">
          <div class="box ">
            <div class="w1180 track-form">
              <div class="form3 clearfix">
                <div class="clearfix">
                  <textarea
                    v-model="form.no"
                    name="textarea"
                    rows="2"
                    cols="20"
                    id="textarea"
                    placeholder="多个运单号用英文逗号分隔"
                  ></textarea>
                  <button
                    value="物流查询 / TRACK"
                    class="button"
                    style="outline: none;"
                    @click="onSubmit"
                  >
                    物流查询 / TRACK
                  </button>
                  <button
                    type="button"
                    class="upload-btn"
                    onclick="javascript:window.location.href='/idcard'"
                    style="outline: none;"
                  >
                    身份证上传 / UPLOAD
                  </button>
                  <div class="track-tel hidden-phone">
                    <img src="../../assets/images/tel.png" />
                  </div>
                </div>
              </div>
            </div>
            <div class="w1180">
              <h2 class="f26 index-title">
                产品业务<span class="f18 n" style="color: #486c84">
                  / Product</span
                >
                <i class="f12 pl20" style="color: #888"
                  >产品服务多样化，提供澳洲本土快递、澳中国际空运与海运等专业化物流解决方案。</i
                >
              </h2>

              <div class="service clearfix">
                <ul class="tc">
                  <li>
                    <a href="">
                      <div class="icon1 icon"><span></span></div>
                      <br />
                      <b>澳中超快件</b><br />
                      <p>澳中“优先行”转运服务</p>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <div class="icon2 icon"><span></span></div>
                      <br />
                      <b>澳中特惠件</b><br />
                      <p>澳中“经济行”转运服务</p>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <div class="icon4 icon"><span></span></div>
                      <br />
                      <b>澳中奶粉件</b><br />
                      <p>奶粉包税转运专线</p>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <div class="icon3 icon"><span></span></div>
                      <br />
                      <b>澳洲集货转运</b><br />
                      <p>流水线作业，集中仓储</p>
                    </a>
                  </li>
                  <!--<li>
											<a href="">
												<div class="icon5 icon"><span></span></div>
												<br />
												<b>货品包装</b><br />
												<p>多类型包装，分箱合箱</p>
											</a>
										</li>-->
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!--END: Content -->
        <!--START: Footer -->

        <div class="foot">
          <div class="copyright w1180 tc">
            <p>
              Copyright © 2017-2020 三象速递
              &nbsp;&nbsp;&nbsp;&nbsp;联系电话：+61 1300965539
              &nbsp;&nbsp;&nbsp;&nbsp;地址：Po Box 6635, Point Cook, VIC
              3030&nbsp;&nbsp;&nbsp;&nbsp;<a
                href="http://www.kuaidi100.com/"
                target="_blank"
                >快递查询</a
              >
            </p>
          </div>
        </div>
        <!-- 代码部分end -->

        <!-- End Footer -->
      </div>
    </div>

    <!-- aside right -->
    <aside
      class="ui-aside asideRight w23"
      :style="
        showAsideRight
          ? 'top: 0px; bottom: 0px; right: -1px; width: 120px;'
          : 'top: 0px; bottom: 0px; right: -4rem; width: 0'
      "
    >
      <ul>
        <li>
          <a href="/">首页</a>
        </li>
        <li>
          <a href="/service">特色服务</a>
        </li>
        <li>
          <a href="/about">关于三象</a>
        </li>
        <li><a href="/contact">联系三象</a></li>
      </ul>
    </aside>
    <!-- </form> -->
  </div>
</template>

<script>
import banner0 from "@/assets/images/banner1.jpg";
import banner1 from "@/assets/images/banner1.jpg";
import banner2 from "@/assets/images/banner1.jpg";

export default {
  data() {
    return {
      banner0,
      banner1,
      banner2,
      form: {
        no: ""
      },
      showAsideRight: false
    };
  },
  computed: {
    height() {
      const clientWidth = document.documentElement.clientWidth;
      const h = (clientWidth * 460) / 1920 + "px";
      return h;
    }
  },
  methods: {
    changeMenu() {
      //移动端右侧滑出
      this.showAsideRight = true;
    },
    hidenAsideRight() {
      this.showAsideRight = false;
    },
    onSubmit() {
      const { no } = this.form;
      // this.$router.push(`/logistics?no=${no}`);
      if (this.isWechatEnv()) {
        this.$router.push(`/Search-wx.aspx?no=${no}`);
      } else {
        this.$router.push(`/searchtracking?no=${no}`);
      }
    },
    isWechatEnv() {
      var ua = navigator.userAgent.toLowerCase();
      var isWeixin = ua.indexOf("micromessenger") !== -1;
      return isWeixin;
    }
  }
};
</script>

<style lang="less">
.banner_img {
  width: 100%;
  height: calc(100vw * 460 / 1920);
}
.el-icon-s-unfold {
  font-size: 28px;
  margin-right: 8px;
}
</style>
