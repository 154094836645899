/*
 * @Descripttion:
 * @FilePath: /sxexpress/src/router/index.js
 * @Author: 张兴业
 * @Date: 2020-10-23 10:45:24
 * @LastEditors: 张兴业
 * @LastEditTime: 2021-06-10 09:24:10
 */
import Vue from "vue";
import VueRouter from "vue-router";
// import Index from "../views/index";
// import MewIndex from "../views/newIndex";
import Main from "@/views/main";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Main",
    component: Main
  },
  {
    path: "/service",
    name: "Service",
    component: () => import("@/views/service")
  },
  {
    path: "/about",
    name: "About",
    component: () => import("@/views/about")
  },
  {
    path: "/about-wx.aspx",
    name: "About-wx",
    component: () => import("@/views/about")
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("@/views/contact")
  },
  {
    path: "/logistics",
    name: "Logistics",
    component: () => import("@/views/logistics")
  },
  {
    path: "/idcard",
    name: "Idcard",
    component: () => import("@/views/idcard")
  },
  {
    path: "/Network2-wx.aspx",
    name: "Idcard-wx",
    component: () => import("@/views/idcard/index-wx.vue")
  },
  // 物流追踪
  {
    path: "/searchtracking",
    name: "SearchTracking",
    component: () => import("../views/searchtracking")
  },
  {
    path: "/Search-wx.aspx",
    name: "SearchTracking-wx",
    component: () => import("../views/searchtracking/index-wx.vue")
  }
  // {
  //   path: "/login",
  //   name: "login",
  //   component: () => import("../views/login"),
  //   beforeEnter: (to, from, next) => {
  //     to.params.id = from.path;
  //     next();
  //   }
  // },
  // {
  //   path: "/applyagent",
  //   name: "applyagent",
  //   component: () => import("../views/applyContact/applyAgent")
  // },
  // {
  //   path: "/applyJoinUs",
  //   name: "applyJoinUs",
  //   component: () => import("../views/applyContact/applyJoinUs")
  // },
  // {
  //   path: "/applyOversea",
  //   name: "applyOversea",
  //   component: () => import("../views/applyContact/applyOversea")
  // },
  // {
  //   path: "/changePassword",
  //   name: "changePassword",
  //   component: () => import("../views/userCenter/changePassword")
  // },
  // {
  //   path: "/noticeMsg",
  //   name: "noticeMsg",
  //   component: () => import("../views/userCenter/noticeMsg")
  // },
  // {
  //   path: "/applyAfter",
  //   name: "applyAfter",
  //   component: () => import("../views/applyContact/applyAfter")
  // },

  // // 以下为兴业的老路由代码
  // {
  //   path: "/register/store",
  //   name: "Reginster.Store",
  //   component: () => import("../views/register/store")
  // },
  // {
  //   path: "/register/distributor",
  //   name: "Reginster.Distributor",
  //   component: () => import("../views/register/distributor")
  // },
  // {
  //   path: "/register/result",
  //   name: "Reginster.Result",
  //   component: () => import("../views/register/result")
  // },
  // {
  //   path: "/register/agreement",
  //   name: "Reginster.Agreement",
  //   component: () => import("../views/register/agreement")
  // },
  // {
  //   path: "/consumer/notification",
  //   name: "Consumer.Notification",
  //   component: () => import("../views/commons/noticebook")
  // },
  // {
  //   path: "/download/app/retail",
  //   name: "Download.Store.Retail.App",
  //   component: () => import("../views/download/store/retail")
  // },
  // {
  //   path: "/download/app/distributor",
  //   name: "Download.Store.Distributor.App",
  //   component: () => import("../views/download/store/distributor")
  // },
  // // 兴业的老路由代码结束
  // // 集团介绍
  // {
  //   path: "/companyintr",
  //   name: "CompanyIntr",
  //   component: () => import("../views/companyintr")
  // },
  // //  az海外
  // {
  //   path: "/azabord",
  //   name: "AzAbord",
  //   component: () => import("../views/azabord")
  // },
];
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
const router = new VueRouter({
  mode: "history",
  base: "/",
  routes
});

export default router;
