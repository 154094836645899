/*
 * @Descripttion:
 * @FilePath: /sxexpress/src/main.js
 * @Author: 张兴业
 * @Date: 2020-10-23 10:15:36
 * @LastEditors: 张兴业
 * @LastEditTime: 2021-04-14 11:57:54
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import request from "@/utils/request.js";
// import i18n from "@/utils/i18n.js";
import "@/utils/isMinScreen.js";

import "@/styles/index.less";

import {
  Form,
  FormItem,
  Input,
  Icon,
  Button,
  Link,
  Row,
  Col,
  Select,
  Option,
  OptionGroup,
  Image,
  MessageBox,
  Carousel,
  CarouselItem,
  Upload,
  Collapse,
  CollapseItem,
  Drawer,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Loading,
  Table,
  TableColumn
} from "element-ui";
import "@/styles/element/index.css";
import $message from "@/utils/element.js";

import { CountDown, Dialog, Steps, Step } from "vant";
import "vant/lib/index.css";

Vue.use(CountDown);
Vue.use(Dialog);
Vue.use(Steps);
Vue.use(Step);

// Vue.use($);

Vue.config.productionTip = false;

Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Icon);
Vue.use(Button);
Vue.use(Link);
Vue.use(Row);
Vue.use(Col);
Vue.use(Image);
Vue.use(Carousel);
Vue.use(CarouselItem);

Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Upload);

Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Drawer);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Loading);

Vue.prototype.$request = request;

//将$message挂载到this上
Vue.prototype.$message = $message;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$alert = MessageBox.alert;

new Vue({
  router,
  store,
  // i18n,
  render: h => h(App)
}).$mount("#app");
